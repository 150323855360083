<template>
  <section
    class="section-container"
    style="width: 100%;"
  >
    <div
      v-if="!subtitles || burningSubtitles"
      style="display: flex; justify-content: center; flex-direction: column; align-items: center;"
    >
      <img
        width="100px"
        src="./../../public/img/loading.gif"
      />
      <br />
      <p>{{ burningSubtitles ? $t('Incrustation') : $t('Génération') }} {{ $t('onGoingSub') }}
      </p>

    </div>
    <div
      v-show="subtitles && !burningSubtitles"
      class="tile is-vertical"
    >

      <div class="tile is-parent">
        <div
          class="tile is-child is-7"
          style="max-height: 465px; overflow-y: scroll;padding-right: 15px;margin-right: 30px !important; "
          ref="subtitlesContainer"
        >

          <div
            v-for="(subtitle, index) in subtitles"
            :key="index"
            class="subtitle-item"
            :class="{ 'active-subtitle': isActiveSubtitle(index) }"
          >

            <input
              maxlength="90"
              v-model="subtitle.text"
              :class="{ 'active-subtitle': isActiveSubtitle(index) }"
              @input="updateSubtitleText(index, $event.target.value)"
              @click="seekToSubtitleStart(index)"
            />

            <!-- <b-button
              @click="deleteSubtitle(index)"
              size="is-small"
              icon-right="trash"
            />
            <b-button
              @click="addSubtitle"
              size="is-small"
              icon-right="add"
            /> -->
          </div>

        </div>
        <div
          class="tile is-child is-5"
          id="right-part_subtitles"
        >

          <div class="video-container">
            <!-- Lecteur vidéo -->
            <video
              ref="videoPlayer"
              width="100%"
              @loadedmetadata="updateTotalTime"
              @timeupdate="handleTimeUpdate"
            >
              <source
                :src="interview.squareMedia.previewUrl"
                type="video/mp4"
              />
              Votre navigateur ne supporte pas la balise vidéo.
            </video>

            <!-- Conteneur pour les sous-titres -->
            <!-- <div class="blocUser">{{ interview.user.surname[0].toUpperCase() + interview.user.surname.slice(1) }} {{ interview.user.name[0].toUpperCase()}} </div> -->

            <div
              class="subtitles"
              v-if="currentSubtitle"
            >{{ currentSubtitle.text }}</div>

          </div>

          <!-- Contrôles de vidéo -->
          <div class="video-controls">
            <button
              @click="togglePlay"
              class="play-pause-btn"
            >

              <b-icon
                v-if="isPlaying"
                icon="pause"
                size="is-medium"
              >
              </b-icon>
              <b-icon
                v-else
                icon="play"
                size="is-medium"
              >
              </b-icon>

            </button>
            <span class="time">{{ formatTime(currentTimeInSeconds) }}</span>
            <input
              type="range"
              min="0"
              :max="maxSliderValue"
              v-model.number="currentSliderValue"
              @input="seekVideo"
              class="progress-bar"
            />
            <span class="time">{{ totalTime }}</span>

          </div>
          <div style="display: flex; margin-top: 30px;">
            <b-button
              @click="burnSubtitles()"
              type="is-primary"
              expanded
            >{{ $t('exportSubtitled') }}</b-button>
          </div>

        </div>
      </div>
    </div>

  </section>
</template>
  
<script>
/**
 * @name SubEditor
 * @desc Component for editing subtitles in a video
 */
export default {
  props: {
    /**
     * Interview object containing subtitle information
     */
    interview: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /**
       * @desc Flag indicating if the video is currently playing
       * @type {boolean}
       */
      isPlaying: false,
      /**
       * @desc Total time of the video
       * @type {string}
       */
      totalTime: '00:00',
      /**
       * @desc Current time of the video
       * @type {string}
       */
      currentTime: '00:00',
      /**
       * @desc Array of subtitles
       * @type {array}
       */
      subtitles: null,
      /**
       * @desc SRT content for subtitles
       * @type {string}
       */
      srtContent: '',
      /**
       * @desc Currently active subtitle
       * @type {object}
       */
      currentSubtitle: null,
      /**
       * @desc Current time in seconds
       * @type {number}
       */
      currentTimeInSeconds: 0,
      /**
       * @desc Current value of the slider for video progress
       * @type {number}
       */
      currentSliderValue: 0,
      /**
       * @desc Maximum value of the slider for video progress
       * @type {number}
       */
      maxSliderValue: 0,
      /**
       * @desc Index of the active subtitle
       * @type {number}
       */
      activeSubtitleIndex: -1,
      /**
       * @desc Flag indicating if subtitles are being burned into the video
       * @type {boolean}
       */
      burningSubtitles: false,
      /**
       * @desc Timeout for saving subtitles
       * @type {number}
       */
      saveTimeout: null
    };
  },

  mounted() {
    this.$refs.videoPlayer.addEventListener(
      'loadedmetadata',
      this.initializeSlider
    );
    console.log(this.interview);
    if (this.interview.subtitles) {
      this.subtitles = this.parseJsonStringSubtitles(this.interview.subtitles);
      console.log(this.subtitles);
      console.log(!this.subtitles || this.burningSubtitles);
    } else {
      this.$store
        .dispatch('generateSubtitles', {
          id: this.interview.id
        })
        .then((response) => {
          this.interview.subtitles = response.data.subtitles;
          this.subtitles = this.parseJsonStringSubtitles(
            this.interview.subtitles
          );
        });
    }
  },

  methods: {
    /**
     * @name seekToSubtitleStart
     * @desc Seeks to the start of a subtitle
     * @param {number} index - Index of the subtitle
     */
    seekToSubtitleStart(index) {
      const subtitle = this.subtitles[index];
      if (subtitle && this.$refs.videoPlayer) {
        this.$refs.videoPlayer.currentTime = subtitle.start + 0.01;
        this.$refs.videoPlayer.pause(); // Pause the video
        this.isPlaying = false; // Update playback status
      }
    },
    /**
     * @name isActiveSubtitle
     * @desc Checks if a subtitle is currently active
     * @param {number} index - Index of the subtitle
     * @returns {boolean} - True if the subtitle is active
     */
    isActiveSubtitle(index) {
      const subtitle = this.subtitles[index];
      const currentTime = this.$refs.videoPlayer.currentTime;
      return currentTime >= subtitle.start && currentTime < subtitle.end;
    },
    /**
     * @name handleTimeUpdate
     * @desc Handles time updates in the video
     */
    handleTimeUpdate() {
      const video = this.$refs.videoPlayer;
      if (video) {
        this.currentTimeInSeconds = video.currentTime; // Update currentTimeInSeconds
        this.currentSliderValue = video.currentTime; // Update currentSliderValue for the progress bar
        this.updateSubtitle();
      }
    },
    /**
     * @name updateSubtitleText
     * @desc Updates the text of a subtitle
     * @param {number} index - Index of the subtitle
     * @param {string} newText - New text for the subtitle
     */
    updateSubtitleText(index, newText) {
      this.subtitles[index].text = newText;
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      this.saveTimeout = setTimeout(() => {
        this.$store
          .dispatch('saveSubtitles', {
            id: this.interview.id,
            subtitles: this.subtitles
          })
          .then(() => {
            this.saveTimeout = null;
            this.$buefy.toast.open({
              message: this.$t('subtitlesSaved'),
              type: 'is-success',
              position: 'is-bottom-right'
            });
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: this.$t('errorSubtitles'),
              type: 'is-danger',
              position: 'is-bottom-right'
            });
          });
      }, 2000);
    },
    /**
     * @name addSubtitle
     * @desc Adds a new subtitle
     */
    addSubtitle() {
      if (!this.subtitles) {
        this.subtitles = [];
      }
      this.subtitles.push({
        text: '',
        start: 0,
        end: 0
      });
    },
    /**
     * @name deleteSubtitle
     * @desc Deletes a subtitle
     * @param {number} index - Index of the subtitle to delete
     */
    deleteSubtitle(index) {
      this.subtitles.splice(index, 1);
    },
    /**
     * @name updateSubtitle
     * @desc Updates the active subtitle based on current time
     */
    updateSubtitle() {
      const currentTime = this.$refs.videoPlayer.currentTime;
      const newActiveIndex = this.subtitles.findIndex(
        (s) => currentTime >= s.start && currentTime <= s.end
      );
      if (newActiveIndex !== -1) {
        this.activeSubtitleIndex = newActiveIndex;
        this.currentSubtitle = this.subtitles[newActiveIndex];
        this.scrollIntoView(
          this.$refs.subtitlesContainer.children[newActiveIndex]
        );
      } else {
        this.activeSubtitleIndex = -1;
        this.currentSubtitle = null;
      }
    },
    /**
     * @name burnSubtitles
     * @desc Burns subtitles into the video
     */
    async burnSubtitles() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }
      await this.$store.dispatch('saveSubtitles', {
        id: this.interview.id,
        subtitles: this.subtitles
      });
      this.burningSubtitles = true;
      this.$store
        .dispatch('burnSubtitles', {
          id: this.interview.id
        })
        .then((response) => {
          this.burningSubtitles = false;
          this.interview = response.data;
          this.$emit('doneBurning', this.interview);
        });
    },
    /**
     * @name isElementInViewport
     * @desc Checks if an element is in the viewport
     * @param {Element} el - The element to check
     * @returns {boolean} - True if the element is in the viewport
     */
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    /**
     * @name scrollIntoView
     * @desc Scrolls an element into view
     * @param {Element} element - The element to scroll into view
     */
    scrollIntoView(element) {
      const container = this.$refs.subtitlesContainer;
      const elementTop = element.offsetTop;
      const containerTop = container.scrollTop;
      const containerHeight = container.clientHeight;

      const targetScrollTop = elementTop - containerHeight / 2;

      const scrollDuration = 100;

      const start = Date.now();
      const animateScroll = function () {
        const elapsedTime = Date.now() - start;
        const fraction = elapsedTime / scrollDuration;

        const easeInOutQuad = (t) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

        if (fraction < 1) {
          container.scrollTop =
            containerTop +
            (targetScrollTop - containerTop) * easeInOutQuad(fraction);
          requestAnimationFrame(animateScroll);
        } else {
          container.scrollTop = targetScrollTop;
        }
      };

      animateScroll();
    },
    /**
     * @name parseJsonStringSubtitles
     * @desc Parses JSON string subtitles
     **/
    parseJsonStringSubtitles(subtitles) {
      return JSON.parse(subtitles);
    },

    /**
     * @name extractTextFromSubtitles
     * @desc Extracts text from an array of subtitles
     * @param {array} subtitles - Array of subtitle objects
     * @returns {string} - Concatenated text from subtitles with line breaks
     */
    extractTextFromSubtitles(subtitles) {
      return subtitles.map((subtitle) => subtitle.text).join('\n\n');
    },

    /**
     * @name convertSrtTimeToSeconds
     * @desc Converts SRT time format to seconds
     * @param {string} srtTime - SRT time format (HH:MM:SS,SSS)
     * @returns {number} - Total time in seconds
     */
    convertSrtTimeToSeconds(srtTime) {
      const [hours, minutes, seconds] = srtTime.split(':');
      const totalSeconds =
        parseInt(hours, 10) * 3600 +
        parseInt(minutes, 10) * 60 +
        parseFloat(seconds.replace(',', '.'));
      return totalSeconds;
    },

    /**
     * @name togglePlay
     * @desc Toggles play/pause of the video player
     */
    togglePlay() {
      const video = this.$refs.videoPlayer;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },

    /**
     * @name seekVideo
     * @desc Seeks the video to the current slider value
     */
    seekVideo() {
      const video = this.$refs.videoPlayer;
      video.currentTime = this.currentSliderValue;
    },

    /**
     * @name initializeSlider
     * @desc Initializes the slider based on video duration
     */
    initializeSlider() {
      const video = this.$refs.videoPlayer;
      this.maxSliderValue = video.duration;
    },

    /**
     * @name syncSlider
     * @desc Syncs the slider with the current video time
     */
    syncSlider() {
      const video = this.$refs.videoPlayer;
      if (video) {
        this.currentSliderValue = video.currentTime;
      }
    },

    /**
     * @name updateTotalTime
     * @desc Updates the total time of the video
     */
    updateTotalTime() {
      const video = this.$refs.videoPlayer;
      this.totalTime = this.formatTime(video.duration);
      this.duration = video.duration; // Make sure to set the duration here
    },

    /**
     * @name updateCurrentTime
     * @desc Updates the current time of the video
     */
    updateCurrentTime() {
      const video = this.$refs.videoPlayer;
      if (video) {
        this.currentTimeInSeconds = video.currentTime;
      }
    },

    /**
     * @name formatTime
     * @desc Formats time in seconds to HH:MM:SS format
     * @param {number} timeInSeconds - Time in seconds
     * @returns {string} - Formatted time string
     */
    formatTime(timeInSeconds) {
      const seconds = Math.floor(timeInSeconds % 60);
      const minutes = Math.floor((timeInSeconds / 60) % 60);
      const hours = Math.floor((timeInSeconds / 3600) % 24);
      const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
      const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
      return `${hours}:${minutesStr}:${secondsStr}`;
    }
  }
};
</script>
  
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');

.video-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.play-pause-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.time {
  margin: 0 12px;
  width: 80px;
}

.progress-bar {
  -webkit-appearance: none;
  width: 100%;
  /* Ajustez la largeur si nécessaire */
  height: 20px !important;
  /* Ajustez l'épaisseur de la barre si nécessaire */
  border-radius: 5px;
  background: #e7e1ff;
  /* Couleur de fond de la barre de progression */
  outline: none;
  padding: 0px;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  /* Largeur du curseur de la barre de progression */
  height: 30px;
  /* Hauteur du curseur de la barre de progression */
  background: #f1054d;
  /* Couleur du curseur de la barre de progression */
  cursor: pointer;
}

.blocUser {
  position: absolute;
  font-size: 13px;
  bottom: 17%;
  height: 25px;
  text-align: center;
  color: white;
  background-color: #0ecb17;
  padding: 2px 10px 2px 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.subtitles {
  position: absolute;
  font-size: 15px;
  font-family: 'Noto Sans', sans-serif;
  bottom: 3%;
  width: 94.1%;
  height: 62px;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 18px;
  padding: 5px 25px 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-subtitle {
  background-color: #e7e1ff !important;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;
  height: 60px !important;
}

input:focus,
textarea:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.subtitle-item {
  display: flex;
  margin-bottom: 8px;
}

.subtitle-item input {
  width: 100%;
  height: 60px !important;
  border: 1px solid#c7c6c6;
  font-size: 15px;
  color: #161032;
  padding: 15px;
  margin-bottom: 5px;
  border-radius: 5px;
}

#right-part_subtitles {
  padding-left: 10px;
  margin-right: -30% !important;
}

@media all and (max-width: 889px) {
  .section-container {
    width: 580px;
  }

  #right-part_subtitles {
    margin-right: -10% !important;
  }
}

@media all and (min-width: 1215px) {
  .section-container {
    width: 1000px;
  }

  #right-part_subtitles {
    margin-right: -30% !important;
  }
}

@media all and (min-width: 300px) and (max-width: 888px) {
  .section-container .is-parent {
    width: 550px;
    display: flex;
    flex-direction: column-reverse;
    height: 630px;
  }

  #right-part_subtitles {
    margin-right: 0 !important;
  }
}
</style>