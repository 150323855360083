import Vue from 'vue'
import Vuex from 'vuex'
import axios, { Axios } from 'axios'
import router from '../router'
import { getAuth, setPersistence, GoogleAuthProvider, FacebookAuthProvider, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, signOut, browserLocalPersistence, getIdToken, createUserWithEmailAndPassword, sendPasswordResetEmail, updateEmail, deleteUser } from "firebase/auth";
import i18n from '../i18n'

Vue.use(Vuex)
const axiosAuth = axios.create();

export default new Vuex.Store({
    state: {
        user: null,
        startTour: false,
        firebaseUser: null,
        missions: [],
        interviews: [],
        clients: [],
        loaded: false,
        currentDuplicatedMissionId: null,
        // playlist: null,
        appsumoCode: null,
        appsumoCodeError: false,
        playlists: null,
    },
    mutations: {
        setAppsumoCodeError(state, payload) {
            console.log("setAppsumoCodeError", payload);
            state.appsumoCodeError = payload;
        },
        setFirebaseUser(state, payload) {
            state.firebaseUser = payload;
        },
        setUser(state, payload) {
            state.user = payload;
            state.loaded = true;
        },
        setMissions(state, payload) {
            state.missions = payload;
        },
        setInterviews(state, payload) {
            state.interviews = payload;
        },
        setAppsumoCode(state, payload) {
            state.appsumoCode = payload;
        },

        setCurrentDuplicatedMissionId(state, payload) {
            state.currentDuplicatedMissionId = payload;
        },
        // setPlaylist(state, payload) {
        //     if (payload.medias) {
        //         payload.medias = payload.medias.map(media => JSON.parse(media));
        //     }
        //     state.playlist = payload;
        //     console.log(state.playlist);
        // },
        setClients(state, payload) {
            state.clients = payload;
        },
        triggerStartTour(state) {
            state.startTour = !state.startTour;
        },
        setPlaylists(state, payload) {
            state.playlists = payload;
        },
    },
    actions: {
        async setUser({ commit }, payload) {
            if (payload && payload.accessToken) {
                if (payload.uid) {
                    commit('setFirebaseUser', payload);
                }
                try {
                    let response;
                    try {
                        response = await axios.get(`${process.env.VUE_APP_API_URL}/charlie/me`, {
                            headers: {
                                Authorization: `Bearer ${payload.accessToken}`
                            }
                        });
                    } catch (error) {
                        let data = {
                            email: payload.email,
                        }
                        if (this.state.appsumoCode && this.state.appsumoCode !== "") {
                            data.appsumoCode = this.state.appsumoCode;
                            commit('setAppsumoCode', null);
                        }
                        try {
                            response = await axios.post(`${process.env.VUE_APP_API_URL}/charlie/user`, { ...data }, {
                                headers: {
                                    Authorization: `Bearer ${payload.accessToken}`
                                }
                            });
                        } catch (error) {
                            console.error(error);
                            const currentUser = getAuth().currentUser;
                            if (currentUser && data.appsumoCode) {
                                await deleteUser(currentUser);
                            }
                            commit('setAppsumoCodeError', true);
                            commit('setUser', null);
                            return;
                        }
                        window.fpr("referral", { email: data.email });
                    }

                    if (response?.data) {
                        commit('setUser', { ...response.data, accessToken: payload.accessToken });
                        if (response.data.name) {
                            i18n.locale = response.data.locale ? response.data.locale : i18n.locale;
                        }
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            user_id: response.data.id,
                        });

                        if (router.currentRoute.path === '/login') {
                            router.push('/')
                        }
                    }

                } catch (error) {
                    console.error(error);
                    commit('setUser', null);
                }
            } else {
                commit('setUser', null);
            }
        },
        async getShortLink({ commit }, payload) {
            const url = payload.url;
            const response = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.VUE_APP_FIREBASE_API_KEY}`, {
                longDynamicLink: process.env.VUE_APP_SHORT_LINK_BASE + `?link=${url}`,
            });
            const { shortLink, previewLink } = (await response).data;
            return shortLink;
        },
        async addOwnerLog({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.interviewId}/ownerLog`, {
                type: payload.type,
                interviewId: payload.interviewId,
            });
        },
        async setupAxios() {
            axiosAuth.interceptors.request.use(async (config) => {
                try {
                    const token = await getIdToken(this.getters.firebaseUser, false)
                    config.headers.Authorization = `Bearer ${token}`;
                    return config;
                } catch (error) {
                    //console.error(error);
                    return Promise.reject(error)
                }
            }, error => {
                console.error(error);
                return Promise.reject(error)
            });
        },
        async signOut({ commit }) {
            this.dispatch('setUser', null);
            return signOut(getAuth());
        },
        autoSignIn({ commit }, payload) {
            this.dispatch('setUser', payload);
        },
        async signInWithEmail({ commit }, payload) {
            this.state.loaded = false;
            console.info(commit);
            await setPersistence(getAuth(), browserLocalPersistence);
            return signInWithEmailAndPassword(getAuth(), payload.email, payload.pwd);
        },
        async signUpWithEmail({ commit }, payload) {
            this.state.loaded = false;
            await setPersistence(getAuth(), browserLocalPersistence);
            if (payload.appsumoCode && payload.appsumoCode !== "") {
                commit('setAppsumoCode', payload.appsumoCode);
            }
            return createUserWithEmailAndPassword(getAuth(), payload.email, payload.pwd);
        },
        async signInWithGoogle() {
            this.state.loaded = false;
            await setPersistence(getAuth(), browserLocalPersistence);
            return signInWithPopup(getAuth(), new GoogleAuthProvider());
        },
        async signInWithGoogleRedirect() {
            this.state.loaded = false;
            await setPersistence(getAuth(), browserLocalPersistence);
            return signInWithRedirect(getAuth(), new GoogleAuthProvider());
        },
        async signInWithFacebook() {
            this.state.loaded = false;
            await setPersistence(getAuth(), browserLocalPersistence);
            return signInWithPopup(getAuth(), new FacebookAuthProvider());
        },
        async askResetWithEmail({ commit }, payload) {
            return sendPasswordResetEmail(getAuth(), payload.email);
        },
        // signInWithApple() {
        //     return Auth.signInWithRedirect(new OAuthProvider('apple.com')).catch(function (error) { console.error(error) });
        // }

        async getAuthProvider({ commit }, payload) {
            const auth = getAuth();
            return auth.currentUser.providerData[0].providerId;
        },

        async updateCharlieUser({ commit }, payload) {
            let user = {};
            console.log(payload);
            if (payload.email && payload.email !== "" && payload.email && payload.password && payload.password !== "") {
                try {
                    await this.dispatch("signInWithEmail", { email: this.getters.activeUser.email, pwd: payload.password })
                    await updateEmail(getAuth().currentUser, payload.email);
                    user = {
                        ...user,
                        email: payload.email
                    }
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            } else {
                user = {
                    ...user,
                    email: this.getters.activeUser.email
                }
            }
            user = {
                ...user,
                name: payload.name && payload.name !== "" ? payload.name : this.getters.activeUser.name,
                surname: payload.surname && payload.surname !== "" ? payload.surname : this.getters.activeUser.surname,
                phoneNumber: payload.phoneNumber && payload.phoneNumber !== "" ? payload.phoneNumber : this.getters.activeUser.phoneNumber,
                color: payload.color && payload.color !== "" ? payload.color : this.getters.activeUser.color,
                logoUrl: payload.logoUrl && payload.logoUrl !== "" ? payload.logoUrl : this.getters.activeUser.logoUrl,
                business: payload.business && payload.business !== "" ? payload.business : this.getters.activeUser.business,
                locale: payload.locale && payload.locale !== "" ? payload.locale : i18n.locale,
            }

            await axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/me`, user);
            this.dispatch('setUser', {
                accessToken: this.state.user.accessToken,
            });
        },

        getInterviewTemplates({ commit }) {
            axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates`).then(response => {
                if (response?.data) {
                    commit('setMissions', response.data);
                }
            }).catch(error => {
                console.error(error);
            });
        },

        getInterviews({ commit }) {
            axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviews`).then(response => {
                if (response?.data) {
                    commit('setInterviews', response.data);
                }
            }).catch(error => {
                console.error(error);
            });
        },

        async getInterviewTemplate({ commit }, id) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${id}`);
        },

        async createInterviewTemplate({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates`, payload)
        },

        async startInterviewTemplate({ commit }, payload) {
            if (payload.blob) {
                let response = await axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}/media/${payload.filename}`);
                console.log(response);
                let config = {
                    onUploadProgress: (progressEvent) => {
                        this.progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                    headers: { 'Content-Type': 'application/octet-stream' }
                };
                const upload = await axios.put(response.data, payload.blob, config);
            }
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}/start/${payload.blob ? '?mediaExternalId=' + payload.filename : ''}`, { withSequence: payload.withSequence !== undefined ? payload.withSequence : false });
        },

        async updateInterviewTemplate({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}`, payload.data)
        },

        async getQuestionsForInterview({ commit }, extId) {
            const data = (await axios.get(`${process.env.VUE_APP_API_URL}/charlie/questions/${extId}`)).data;
            i18n.locale = data.locale ? data.locale : i18n.locale;
            return data;
        },

        async cancelInterview({ commit }, extId) {
            return axios.put(`${process.env.VUE_APP_API_URL}/charlie/interviews/${extId}/cancel`, {})
        },

        async getScenarioForInterview({ commit }, extId) {
            try {
                const data = (await axios.get(`${process.env.VUE_APP_API_URL}/charlie/questions/${extId}`)).data;
                console.log(data);
                const questions = data.questions;
                const user = data.user;
                const owner = data.owner;
                const customMessage = data.customMessage;
                const customMedia = data.customMedia;
                i18n.locale = data.locale ? data.locale : i18n.locale;
                let scenario = {
                    1: {
                        type: "CharlieText",
                        class: "chat__message_A",
                        id: "1",
                        props: { content: i18n.t('hiCharlie', { userSurname: user.surname }) },
                        wait: 2000,
                        waitInput: false,
                        nextStep: "2",
                    },
                    2: {
                        type: "CharlieText",
                        class: "chat__message_A",
                        id: "2",
                        props: { content: i18n.t('questionToWitness', { ownerSurname: owner.surname }) },
                        wait: 2000,
                        waitInput: false,
                        nextStep: "3",
                    }
                };

                let lastId = 2;
                if (customMedia || customMessage) {
                    scenario[lastId + 1] = {
                        type: "CharlieText",
                        class: "chat__message_A",
                        id: lastId + 1,
                        props: { content: (i18n.t('beforeStartingMessage', { ownerSurname: owner.surname })) },
                        waitInput: false,
                        wait: 2000,
                        nextStep: lastId + 2
                    }
                    lastId++;
                    if (customMedia) {
                        scenario[lastId + 1] = {
                            type: "CharliePlayer",
                            class: "chat__message_A",
                            id: lastId + 1,
                            props: {
                                url: customMedia.url
                            },
                            waitInput: false,
                            wait: 10000,
                            nextStep: lastId + 2
                        }
                        lastId++;
                    } else if (customMessage) {
                        scenario[lastId + 1] = {
                            type: "CharlieText",
                            class: "chat__message_A",
                            id: lastId + 1,
                            props: { content: customMessage },
                            waitInput: false,
                            wait: 4000,
                            nextStep: lastId + 2
                        }
                        lastId++;
                    }
                }


                for (let question of questions) {
                    scenario[lastId + 1] = {
                        type: "CharlieText",
                        class: "chat__message_A",
                        id: lastId + 1,
                        props: { content: question.text },
                        waitInput: false,
                        wait: 2000,
                        nextStep: lastId + 2
                    }
                    scenario[lastId + 2] = {
                        type: "CharlieRecorder",
                        class: "chat__message_A",
                        id: lastId + 2,
                        props: {
                            type: "video",
                            questionId: question.id,
                            interviewExternalId: extId,
                            validationIndex: lastId + 3,
                            signedUrl: question.signedUrl,
                        },
                        waitInput: true
                    }
                    lastId += 2;
                }

                scenario[lastId + 1] = {
                    type: "CharlieText",
                    class: "chat__message_A",
                    id: lastId + 1,
                    props: { content: (i18n.t('acceptImageRights', { ownerBusiness: owner.business })) },
                    wait: 2000,
                    waitInput: false,
                    nextStep: lastId + 2,
                }
                lastId++;
                scenario[lastId + 1] = {
                    type: "CharlieRightsCession",
                    class: "chat__message_A",
                    id: lastId + 1,
                    props: {
                        ownerBusiness: owner.business,
                        user: user,
                        externalId: extId,
                        validationIndex: lastId + 2
                    },
                    waitInput: true,
                    nextStep: lastId + 2,
                }
                lastId++;
                scenario[lastId + 1] = {
                    type: "CharlieText",
                    class: "chat__message_A",
                    id: lastId + 1,
                    props: { content: (i18n.t('seeYouSoon')) },
                    wait: 2000,
                    waitInput: true,
                    nextStep: lastId + 2,
                }
                return scenario;
            } catch (error) {
                return {
                    1: {
                        type: "CharlieText",
                        class: "chat__message_A",
                        id: 1,
                        props: { content: i18n.t('testimonyCollected') },
                        wait: 2000,
                        waitInput: true,
                        nextStep: "2",
                    }
                };
            }

        },

        async sendAnswer({ commit }, payload) {
            console.log(payload);
            const upload = await axios.put(payload.signedUrl, payload.blob, payload.config)

            return axios.post(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.interviewExternalId}/answer/${payload.questionId}`, {
                duration: payload.duration,
                madeWith: payload.madeWith,
            })
        },

        async updateInterviewTemplateWithMedia({ commit }, payload) {
            let response = await axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}/media/${payload.filename}`);
            console.log(response);
            let config = {
                onUploadProgress: (progressEvent) => {
                    this.progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                },
                headers: { 'Content-Type': 'application/octet-stream' }
            };
            const upload = await axios.put(response.data, payload.blob, config);
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}/media/${payload.filename}`, {});
        },

        unlockInterview({ commit }, id) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviews/${id}/unlock`, {});
        },

        async createStripeSession({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/payments/session`, payload);
        },

        async getInvoices({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/invoices`);
        },

        async getApiKey({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/me/key`);
        },

        async refreshApiKey({ commit }) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/me/key`);
        },

        async adminCreateInvoice({ commit }, invoice) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/invoices`, invoice);
        },

        async getCharlieUserStats({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/me/stats`);
        },

        async getCharlieUserLastInterviewsPreview({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/me/lastInterviewsPreview`);
        },

        async signTerms({ commit }, payload) {
            return axios.put(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.externalId}/terms`, { terms: payload.terms });
        },

        async recorderError({ commit }, payload) {
            return axios.put(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.externalId}/error`, { error: payload.error });
        },

        async getAdminInterviewTemplates({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates`);
        },

        async getAdminInterviewTemplatesPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates`, { params: payload });
        },

        async getAdminUsers({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/users`);
        },

        async getAdminUsersByLogPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/usersByLog`, { params: payload });
        },
        async getAdminClientsByLogPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/clientsByLog`, { params: payload });
        },

        async createAdminClientLog({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/client/${payload.id}/logs`, payload);
        },

        async getAdminUsersPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/users`, { params: payload });
        },

        async getAdminInvoices({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/invoices`);
        },

        async getAdminInvoicesPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/invoices`, { params: payload });
        },

        async deleteInterviewTemplate({ commit }, id) {
            return axiosAuth.delete(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates/${id}`);
        },

        async retryUnlock({ commit }, id) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/${id}/retryUnlock`, {});
        },

        async getNotificationsForInterview({ commit }, id) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/${id}/notifications`);
        },

        async getNotificationsForInterviewTemplates({ commit }, id) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates/${id}/notifications`);
        },

        async cancelNotificationsForInterview({ commit }, id) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/${id}/notifications/cancel`, {});
        },

        async sendSponsorInvitation({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/sponsorships/invite`, payload);
        },
        async acceptSponsorInvitation({ commit }, payload) {
            return axios.post(`${process.env.VUE_APP_API_URL}/charlie/sponsorships/${payload.id}/accept`, payload);
        },
        async getSponsorship({ commit }, payload) {
            return axios.get(`${process.env.VUE_APP_API_URL}/charlie/sponsorships/${payload.id}`);
        },
        async getSponsorships({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/sponsorships`);
        },
        async getAdminSponsorships({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/sponsorships`);
        },
        async getAdminSponsorshipsPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/sponsorships`, { params: payload });
        },
        async getUnrespondedInterviews({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/unresponded`);
        },
        async getUnrespondedInterviewsPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/unresponded`, { params: payload });
        },
        async updateUnrespondedInterviews({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/interviews/${payload.id}/unresponded`, payload);
        },
        async deleteDraftInterview({ commit }, id) {
            return axiosAuth.delete(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${id}`);
        },
        async duplicateMission({ commit }, id) {
            commit('setCurrentDuplicatedMissionId', id);
        },
        async updateUserAdmin({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/users/${payload.id}`, payload);
        },
        async getAdminUserLogs({ commit }, id) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/users/${id}/logs`);
        },
        async createAdminUserLog({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/logs`, payload);
        },
        async updateAdminUserLogs({ commit }, payload) {
            let promises = [];
            for (let log of payload.userLogs) {
                promises.push(axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/logs`, log));
            }
            return Promise.all(promises);
        },
        // async addMediaToPlaylist({ commit }, media) {
        //     console.log("Adding media to playlist", media)
        //     return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/playlists`, media);
        // },
        async getPlaylist({ commit }, id) {
            axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/playlists/user`).then((response) => {
                commit('setPlaylist', response.data ? response.data : { medias: [] });
            });
        },
        // async removeMediaFromPlaylist({ commit }, id) {
        //     return axiosAuth.delete(`${process.env.VUE_APP_API_URL}/charlie/playlists/media/${id}`);
        // },
        async getPublicMailTemplates({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/mailTemplates`, { params: payload });
        },

        async computeMailVariables({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/mailVariableTemplates/compute?interviewId=${payload.interviewId}`);
        },

        async getAllMailTemplates({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates`);
        },
        async getMailTemplatesPaginated({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates`, { params: payload });
        },
        async getMailTemplate({ commit }, id) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates/${id}`);
        },
        async updateMailTemplate({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates/${payload.id}`, payload);
        },
        async createMailTemplate({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates`, payload);
        },
        async deleteMailTemplate({ commit }, id) {
            return axiosAuth.delete(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates/${id}`);
        },
        async sendMailTemplate({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/mailTemplates/${payload.id}/send`, payload);
        },
        async getAllMailVariables({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/mailVariableTemplates`);
        },
        async getAdminStats({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/stats`, { params: payload });
        },
        async getAdminBusinessStats({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/businessStats`, { params: payload });
        },
        async getAdminUserDetails({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/users/${payload.id}`);
        },
        async getAdminSequences({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/sequences`, { params: payload });
        },
        async getAdminClients({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/clients/${payload.id}`);
        },
        async createSequence({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/sequences`, payload);
        },
        async updateSequence({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/sequences/${payload.id}`, payload);
        },
        async startSequence({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/sequences/${payload.id}`, { status: 1 });
        },
        async pauseSequence({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/sequences/${payload.id}`, { status: 2 });
        },
        async createClient({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/clients`, payload);
        },
        async updateClient({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/clients/${payload.id}`, payload);
        },
        async createClients({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/clients/bulk`, payload);
        },
        async deleteClient({ commit }, payload) {
            return axiosAuth.delete(`${process.env.VUE_APP_API_URL}/charlie/clients/${payload.id}`);
        },
        async getClients({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/clients`, { params: payload }).then((response) => {
                commit('setClients', response.data ? response.data : []);
            })
        },
        async stackCode({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/code/stack/${payload.code}`);
        },
        async getUserCodeInfos({ commit }) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/code/infos`);
        },
        async refreshUser({ commit }) {
            this.dispatch('setUser', {
                accessToken: this.state.user.accessToken,
            });
        },

        //Playlists
        // async addMediaToPlaylist({ commit }, media) {
        //     console.log("Adding media to playlist", media)
        //     return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/playlists`, media);
        // },
        async getPlaylists({ commit }) {
            axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/playlists`).then((response) => {
                commit('setPlaylists', response?.data || []);
            });
        },
        async createPlaylist({ commit }, payload) {
            let blob = payload.wallImageBlob;
            delete payload.wallImageBlob;
            const result = await axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/playlists`, payload);
            const playlist = result.data;

            if (blob) {
                let response = await axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/playlists/${playlist.id}/imageSignedUrl`);
                let config = {
                    onUploadProgress: (progressEvent) => {
                        this.progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                    headers: { 'Content-Type': 'application/octet-stream' }
                };
                const upload = await axios.put(response.data, blob, config);
                payload = { ...playlist, rawImageUrl: true };
                await new Promise(r => setTimeout(r, 2000));
                return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/playlists/${playlist.id}`, payload);
            }
            return { data: playlist };
        },
        async updatePlaylist({ commit }, payload) {
            if (payload.wallImageBlob) {
                let response = await axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/playlists/${payload.id}/imageSignedUrl`);
                console.log(response);
                let config = {
                    onUploadProgress: (progressEvent) => {
                        this.progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                    headers: { 'Content-Type': 'application/octet-stream' }
                };
                const upload = await axios.put(response.data, payload.wallImageBlob, config);
                delete payload.wallImageBlob;
                payload.rawImageUrl = true;
            }
            await new Promise(r => setTimeout(r, 2000));
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/playlists/${payload.id}`, payload);
        },
        async generateSubtitles({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.id}/subtitle`, payload);
        },
        async saveSubtitles({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.id}/subtitle`, payload);
        },
        async burnSubtitles({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/charlie/interviews/${payload.id}/burnSubtitles`, payload);
        },

        async createAdminClients({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/clients/${payload.id}`, payload);
        },
        async createAdminInterviewTemplates({ commit }, payload) {
            return axiosAuth.post(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates?ownerUserId=${payload.ownerUserId}`, payload.data);
        },
        async updateadminInterviewTemplates({ commit }, payload) {
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates?ownerUserId=${payload.id}`, { body: payload.body });
        },
        async startAdminInterviewTemplates({ commit }, payload) {
            if (payload.blob) {
                let response = await axiosAuth.get(`${process.env.VUE_APP_API_URL}/charlie/interviewTemplates/${payload.id}/media/${payload.filename}`);
                console.log(response);
                let config = {
                    onUploadProgress: (progressEvent) => {
                        this.progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                    headers: { 'Content-Type': 'application/octet-stream' }
                };
                const upload = await axios.put(response.data, payload.blob, config);
            }
            return axiosAuth.put(`${process.env.VUE_APP_API_URL}/admin/charlie/interviewTemplates/${payload.id}/start/${payload.blob ? '?mediaExternalId=' + payload.filename : ''}`, { withSequence: payload.withSequence !== undefined ? payload.withSequence : false });
        },
        computeAdminMailVariables({ commit }, payload) {
            return axiosAuth.get(`${process.env.VUE_APP_API_URL}/admin/charlie/mailVariableTemplates/compute?interviewId=${payload.interviewId}&ownerId=${payload.ownerId}`);
        }
    },
    getters: {
        activeUser: (state) => {
            return state.user
        },
        missions: (state) => {
            return state.missions
        },
        interviews: (state) => {
            return state.interviews
        },
        clients: (state) => {
            return state.clients
        },
        firebaseUser: (state) => {
            return state.firebaseUser;
        },
        currentDuplicatedMissionId: (state) => {
            return state.currentDuplicatedMissionId;
        },
        // playlist: (state) => {
        //     return state.playlist;
        // },
        appsumoCodeError: (state) => {
            return state.appsumoCodeError;
        },
        playlists: (state) => {
            return state.playlists;
        }
    },
    plugins: [
        store => {
            store.watch(
                state => state.startTour,
                (newValue, oldValue) => {
                    console.log('startTour changed from', oldValue, 'to', newValue);
                }
            );
        }
    ]
})