<template >
  <section style="z-index: 1000 !important">
    <div
      v-if="loading"
      class="loadingContainer"
      :style="{ width:computedWidth + 'px', height: computedWidth + 'px' }"
    >
      <span class="loadingText">{{ $t('sendingVideo') }}</span>
      <b-progress
        :value="progress"
        show-value
        format="percent"
      ></b-progress>
    </div>
    <ultimaterecorder-gis
      style="margin: auto; "
      v-else
      :size="computedWidth"
      :locale="$i18n.locale"
      :duration="30"
      @error="onRecorderError($event)"
      @recorded="onRecorded($event)"
      @ready="$emit('ready')"
    ></ultimaterecorder-gis>
  </section>
</template>



<script>
/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';

import 'ultimaterecorder';

/**
 * @name Recorder
 * @desc Component for recording and uploading video or audio
 */
export default {
  name: 'recorder',
  components: {},
  props: {
    /**
     * Type of recording (video or audio)
     */
    type: {
      type: String,
      default: 'video'
    },
    /**
     * External ID of the interview
     */
    interviewExternalId: {
      type: String,
      default: ''
    },
    /**
     * ID of the question being answered
     */
    questionId: {
      type: Number
    },
    /**
     * Signed URL for uploading the recording
     */
    signedUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      /**
       * @desc Upload progress percentage
       * @type {number}
       */
      progress: 0,
      /**
       * @desc Error object if any error occurs
       * @type {object|null}
       */
      error: null,
      /**
       * @desc Loading state
       * @type {boolean}
       */
      loading: false
    };
  },
  methods: {
    /**
     * @name onRecorded
     * @desc Handles the recorded event and uploads the recording
     * @param {object} event - The recorded event object
     */
    onRecorded(event) {
      console.log('onRecorded', event);
      this.loading = true;
      let filename = uuidv4();
      let datas = {
        filename: filename,
        interviewExternalId: this.interviewExternalId,
        questionId: this.questionId
      };

      datas['blob'] = event.detail.blob;
      datas['signedUrl'] = this.signedUrl;
      this.progress = 0;
      datas['config'] = {
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(this.progress);
        },
        headers: { 'Content-Type': 'application/octet-stream' }
      };
      datas['madeWith'] = navigator.userAgent;
      this.$store
        .dispatch('sendAnswer', datas)
        .then((res) => {
          this.progress = 100;
          this.loading = false;
          this.$emit('done', true);
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.error = error;
        });
    },

    /**
     * @name onRecorderError
     * @desc Handles recorder errors
     * @param {object} event - The error event object
     */
    onRecorderError(event) {
      console.error('onRecorderError', event);
      this.error = event;
      if (event.code === 2) {
        this.$emit('tooShort', true);
      } else {
        this.$emit('error', this.error);
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    /**
     * @name computedWidth
     * @desc Calculates the width of the recorder based on window size
     * @returns {number} The computed width
     */
    computedWidth() {
      return window.innerWidth < 500 ? window.innerWidth - 50 : 500;
    }
  }
};
</script>

<style src="./charlie/charlie.css"></style>

<style>
/* @keyframes slide-in-from-left {
  0% {
    left: -100%
  }

  100% {
    left: 0
  }
}

@keyframes slide-in-from-right {
  0% {
    right: -100%
  }

  100% {
    right: 0
  }
} */
</style>

<style scoped>
.button-3d-revert,
.button-3d {
  top: calc(25% - 5px) !important;
}

.button-3d {
  color: #9b9e11;
  background-color: #d9dd1b;
  border: 1px solid #9b9e11;
}

.button-3d-revert {
  color: #ffffff;
  background-color: black;
  border: 1px solid white;
}

@media all and (max-width: 700px) {
  .button-3d .button-3d-revert {
    height: 45px !important;
  }
}
</style>