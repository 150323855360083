<template>
  <div
    class="card box is-ancestor"
    style="height: 100vh; overflow-x: scroll;"
  >
    <br />
    <b-tabs class="block">
      <b-tab-item label="Infos clients">

        <div
          class=" is-ancestor"
          style="height: 100vh; overflow-x: scroll;"
        >
          <div class="tile is-ancestor">
            <div class="tile is-parent">
              <article class="tile is-child box">
                <h3>
                  🎉 Unlocked :
                  {{ unlockedTestimonials() }}
                </h3>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <h3>
                  🔒 Locked :
                  {{ lockedTestimonials() - unlockedTestimonials() }}
                </h3>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <h3>Scoring : {{ scoring() }}%</h3>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child box">
                <h3>
                  Last contact :
                  {{ lastContact ? lastContact : '' | moment }}
                </h3>
              </article>
            </div>

          </div>

          <div class="tile is-ancestor">
            <div class="tile is-parent is-8 is-vertical">
              <h3>Details</h3>
              <div class="tile box is-child is-vertical">
                <div class="tile">
                  <div class="tile is-vertical is-3">

                    {{ userDetail.surname }} {{ userDetail.name }}<br />
                    {{ userDetail.business }} <br />
                    {{ userDetail.email }} <br />
                    {{ userDetail.phoneNumber }} <br /><br />
                    <div> <b-icon
                        pack="fas"
                        icon="edit"
                        size="is-small"
                        style="height: 18px; float: left; "
                      ></b-icon><input
                        style="border:none; border-bottom: 1px #323232 solid;"
                        placeholder="No tel"
                        v-model="userDetail.phoneNumber"
                      />
                    </div><br />

                    <div>
                      <p>Linkedin :</p>
                      <div style="display: flex; align-items: flex-start; gap:10px">
                        <input
                          v-model="userDetail.linkedinUrl"
                          style="border:none; border-bottom: 1px #323232 solid;"
                        />
                        <a
                          :href="'https://' + userDetail.linkedinUrl"
                          target="_blank"
                        >
                          <b-icon
                            pack="fas"
                            icon="eye"
                            size="is-small"
                            custom-class="fa-eye"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p>Website :</p>
                      <div style="display: flex; align-items: flex-start; gap:10px">
                        <input
                          v-model="userDetail.websiteUrl"
                          style="border:none; border-bottom: 1px #323232 solid;"
                        />
                        <a
                          :href="'https://' + userDetail.websiteUrl"
                          target="_blank"
                        >
                          <b-icon
                            pack="fas"
                            icon="eye"
                            size="is-small"
                            custom-class="fa-eye"
                          >
                          </b-icon>
                        </a>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                  <div
                    class="tile is-vertical is-3"
                    style="gap: 10px;display: flex;align-items: baseline;"
                  >
                    <b-dropdown
                      v-model="userDetail.businessSize"
                      placeholder="taille business"
                      aria-role="list"
                    >
                      <template #trigger="{ active }">
                        <p>Taille Business :</p>
                        <b-button
                          :label="userDetail.businessSize"
                          class="tag"
                          style="width: 200px"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                          height="20"
                        />
                      </template>
                      <b-dropdown-item
                        value="1"
                        aria-role="listitem"
                      >1</b-dropdown-item>
                      <b-dropdown-item
                        value="2-10"
                        aria-role="listitem"
                      >2-10</b-dropdown-item>
                      <b-dropdown-item
                        value="11-50"
                        aria-role="listitem"
                      >11-50</b-dropdown-item>
                      <b-dropdown-item
                        value="51-500"
                        aria-role="listitem"
                      >51-500</b-dropdown-item>
                      <b-dropdown-item
                        value="500+"
                        aria-role="listitem"
                      >500+</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                      v-model="userDetail.businessSector"
                      aria-role="list"
                      height="200"
                      scrollable
                    >
                      <template #trigger="{ active }">
                        <p>Secteur :</p>
                        <b-button
                          :label="userDetail.businessSector"
                          class="tag"
                          style="width: 200px"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                        />
                      </template>
                      <b-dropdown-item
                        value="Administration, fonction publique"
                        aria-role="listitem"
                      >Administration, fonction
                        publique</b-dropdown-item>
                      <b-dropdown-item
                        value="Agroalimentaire"
                        aria-role="listitem"
                      >Agroalimentaire</b-dropdown-item>
                      <b-dropdown-item
                        value="Banques, assurances"
                        aria-role="listitem"
                      >Banques, assurances</b-dropdown-item>
                      <b-dropdown-item
                        value="Commerce, distribution"
                        aria-role="listitem"
                      >Commerce,
                        distribution</b-dropdown-item>
                      <b-dropdown-item
                        value="Communication, marketing, web, video"
                        aria-role="listitem"
                      >Communication,
                        marketing, web, video</b-dropdown-item>
                      <b-dropdown-item
                        value="Conseils juridiques, financier, RH"
                        aria-role="listitem"
                      >Conseils juridiques,
                        financier, RH</b-dropdown-item>
                      <b-dropdown-item
                        value="Edition, Journalisme"
                        aria-role="listitem"
                      >Edition, Journalisme</b-dropdown-item>
                      <b-dropdown-item
                        value="Energie et environnement"
                        aria-role="listitem"
                      >Energie et
                        environnement</b-dropdown-item>
                      <b-dropdown-item
                        value="Enseignement, formation, coach"
                        aria-role="listitem"
                      >Enseignement, formation,
                        coach</b-dropdown-item>
                      <b-dropdown-item
                        value="Événementiel"
                        aria-role="listitem"
                      >Événementiel</b-dropdown-item>
                      <b-dropdown-item
                        value="Hôtellerie, restauration, tourisme"
                        aria-role="listitem"
                      >Hôtellerie, restauration,
                        tourisme</b-dropdown-item>
                      <b-dropdown-item
                        value="Immobilier"
                        aria-role="listitem"
                      >Immobilier</b-dropdown-item>
                      <b-dropdown-item
                        value="Industriel"
                        aria-role="listitem"
                      >Industriel</b-dropdown-item>
                      <b-dropdown-item
                        value="Logistique, transports"
                        aria-role="listitem"
                      >Logistique,
                        transports</b-dropdown-item>
                      <b-dropdown-item
                        value="Métiers artistiques"
                        aria-role="listitem"
                      >Métiers artistiques</b-dropdown-item>
                      <b-dropdown-item
                        value="Métiers du bâtiment"
                        aria-role="listitem"
                      >Métiers du bâtiment</b-dropdown-item>
                      <b-dropdown-item
                        value="Mode, industrie textile"
                        aria-role="listitem"
                      >Mode, industrie
                        textile</b-dropdown-item>
                      <b-dropdown-item
                        value="Santé"
                        aria-role="listitem"
                      >Santé</b-dropdown-item>
                      <b-dropdown-item
                        value="Services à la personne"
                        aria-role="listitem"
                      >Services à la
                        personne</b-dropdown-item>
                      <b-dropdown-item
                        value="Technologie, Logiciel, Hardware"
                        aria-role="listitem"
                      >Technologie, Logiciel,
                        Hardware</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                      v-model="userDetail.positionInCompany"
                      aria-role="list"
                    >
                      <template #trigger="{ active }">
                        <p>Poste occupé :</p>
                        <b-button
                          :label="userDetail.positionInCompany"
                          class="tag"
                          style="width: 200px"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                          height="20"
                        />
                      </template>
                      <b-dropdown-item
                        value="Direction"
                        aria-role="listitem"
                      >Direction</b-dropdown-item>
                      <b-dropdown-item
                        value="Chef d'équipe"
                        aria-role="listitem"
                      >Chef d'équipe</b-dropdown-item>
                      <b-dropdown-item
                        value="Opérateur"
                        aria-role="listitem"
                      >Opérateur</b-dropdown-item>
                      <b-dropdown-item
                        value="Apprenti"
                        aria-role="listitem"
                      >Apprenti</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    class="tile is-vertical is-3"
                    style="gap: 10px;display: flex;align-items: baseline;"
                  >
                    <p>Pays :</p>
                    <country-select
                      v-model="userDetail.businessCountry"
                      :country="userDetail.businessCountry"
                      topCountry="FR"
                      :countryName="true"
                      style="width: 67%;margin-top: -10px;"
                    />
                    <p>Région :</p>
                    <region-select
                      v-model="userDetail.businessRegion"
                      :country="userDetail.businessCountry"
                      :countryName="true"
                      :regionName="true"
                      :region="userDetail.businessRegion"
                      style="width: 67%;margin-top: -6px;margin-bottom:4px"
                    />
                    <b-dropdown
                      v-model="userDetail.businessTarget"
                      aria-role="list"
                    >
                      <template #trigger="{ active }">
                        <p>Cible :</p>
                        <b-button
                          :label="userDetail.businessTarget"
                          class="tag"
                          style="width: 200px"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                          height="20"
                        />
                      </template>
                      <b-dropdown-item
                        value="B2B"
                        aria-role="listitem"
                      >B2B</b-dropdown-item>
                      <b-dropdown-item
                        value="B2C"
                        aria-role="listitem"
                      >B2C</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="tile is-vertical is-3">
                    <span v-if="userDetail.locale == 'fr'">🇫🇷</span>
                    <span v-if="userDetail.locale == 'en'">🇬🇧</span>
                    <span v-if="userDetail.locale == 'es'">🇪🇸</span>
                    <span v-if="userDetail.locale == 'it'">🇮🇹</span>
                    <p>User ID : </p> {{ userDetail.id }} <br /><br />
                    <p>Account Creation : </p>{{ userDetail.createdAt | moment }} <br /><br />

                    <p>

                      <img
                        v-if="userDetail.source == 'appsumo'"
                        src="./../../../public/img/sumo.png"
                        style="width: 25px; height: 25px; float: left !important; margin-right: 10px;"
                      />
                      <b-field v-else>
                        <b-dropdown
                          v-model="userDetail.userSource"
                          aria-role="list"
                        >
                          <template #trigger="{ active }">
                            <p>Source :</p>
                            <b-button
                              :label="userDetail.userSource"
                              class="tag"
                              style="width: 200px"
                              :icon-right="active ? 'menu-up' : 'menu-down'"
                              height="20"
                            />
                          </template>
                          <b-dropdown-item
                            value="search"
                            aria-role="listitem"
                          >Moteur de recherche</b-dropdown-item>
                          <b-dropdown-item
                            value="youtube"
                            aria-role="listitem"
                          >Youtube</b-dropdown-item>
                          <b-dropdown-item
                            value="linkedin"
                            aria-role="listitem"
                          >Linkedin</b-dropdown-item>
                          <b-dropdown-item
                            value="facebook"
                            aria-role="listitem"
                          >Facebook</b-dropdown-item>
                          <b-dropdown-item
                            value="instagram"
                            aria-role="listitem"
                          >Instagram</b-dropdown-item>
                          <b-dropdown-item
                            value="tiktok"
                            aria-role="listitem"
                          >TikTok</b-dropdown-item>
                          <b-dropdown-item
                            value="webikeo"
                            aria-role="listitem"
                          >Webikeo</b-dropdown-item>
                          <b-dropdown-item
                            value="gartner"
                            aria-role="listitem"
                          >Gartner</b-dropdown-item>
                          <b-dropdown-item
                            value="affiliation"
                            aria-role="listitem"
                          >Affiliation</b-dropdown-item>
                          <b-dropdown-item
                            value="referral"
                            aria-role="listitem"
                          >Referral</b-dropdown-item>
                          <b-dropdown-item
                            value="other"
                            aria-role="listitem"
                          >Other</b-dropdown-item>
                        </b-dropdown>

                      </b-field>

                      <img
                        src="./../../../public/img/token.png"
                        style="width: 25px; height: 25px; float: left !important"
                      />&nbsp;&nbsp;{{ userDetail.credits }}
                      <br>
                      <br>
                      <b-field>
                        <b-switch v-model="userDetail.premium">
                          ⭐️ Premium
                        </b-switch>
                      </b-field>
                    </p>

                  </div>
                </div>
                <button
                  @click="saveUserDetail()"
                  class="button-3d-small-revert"
                >Sauvegarder</button>
                <hr />

                <div>
                  <div class="tile is-parent">
                    <div class="tile">
                      <b-field label="Add">
                        <b-select
                          v-model="currentLog.type"
                          placeholder="Select an action"
                        >
                          <option value="FOLLOWUP">Suivi Mensuel</option>
                          <option value="RAPPEL">Rappel</option>
                          <option value="SUPPORT">Support Technique</option>
                          <option value="PHONE_SUPPORT">Échange Téléphonique</option>
                          <option value="MAIL_SUPPORT">Échange Mail</option>
                          <option value="TEXT_SUPPORT">Échange SMS</option>

                          <option value="OTHER">Autre</option>
                        </b-select>
                      </b-field>
                    </div>
                    <div class="tile is-6">
                      <b-field
                        v-if="currentLog.type != 'RAPPEL'"
                        label="Notes"
                        class="inputC"
                      >
                        <b-input
                          v-model="currentLog.content"
                          maxlength="600"
                          type="textarea"
                          style="width: 700px"
                        ></b-input>
                      </b-field>
                      <b-field v-else>

                        <b-datepicker
                          v-model="currentLog.content"
                          inline
                          :first-day-of-week="1"
                        >
                        </b-datepicker>

                      </b-field>
                    </div>
                    <div class="tile">
                      <button
                        @click="createLog()"
                        class="button-3d"
                        style="max-height: 60px"
                      >
                        send
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="tile is-parent is-vertical">
              <h3>Activities</h3>
              <div class="tile box is-child activitiesBox">
                <div
                  v-for="log in logs"
                  :key="log.id"
                >
                  <div class="tile is-parent feed">
                    <div class="tile is-2 timelDate">
                      {{ log.createdAt | moment }}
                    </div>
                    <div
                      class="tile is-2 is-vertical"
                      style="display: flex !important;justify-content: center !important;"
                    >
                      <div
                        class="tile"
                        style="max-height: 30px !important;display: flex !important;justify-content: center !important;"
                      >
                        <div :class="classFromType(log.type)">
                          <b-icon
                            pack="fas"
                            :icon="iconFromType(log.type)"
                            class="timelIcon"
                          >
                          </b-icon>
                        </div>
                      </div>
                      <div class="tile timeLine"></div>
                    </div>
                    <div
                      v-if="log.content"
                      class="tile is-vertical timelNotesPos"
                    >
                      <div class="tile timelMessage">
                        <b-tag>{{ log.operator }}</b-tag>&nbsp;&nbsp;{{ titleFromtype(log) }}
                      </div>
                      <div class="tile">
                        <i
                          v-if="log.type != 'RAPPEL'"
                          class="timelNotes"
                        >{{ log.content }} </i>
                        <i
                          v-else
                          class="timelNotes"
                        >{{ log.content | moment }} </i>
                      </div>
                      <br />
                    </div>
                    <div
                      v-else
                      class="tile is-vertical timelNotesPos"
                    >
                      <div class="tile timelMessage">{{ titleFromtype(log) }}</div>
                      <br />
                    </div>
                  </div>
                </div>
                <!-- ONBOARDING -->
                <div class="tile is-parent feed">
                  <div class="tile is-2 timelDate">
                    {{
                new Date(
                  new Date(userDetail.createdAt).getTime() +
                  5 * 24 * 60 * 60 * 1000
                ) | moment
              }}
                  </div>
                  <div
                    class="tile is-2 is-vertical"
                    style="display: flex !important;justify-content: center !important;"
                  >
                    <div
                      class="tile"
                      style="max-height: 30px !important;display: flex !important;justify-content: center !important;"
                    >
                      <div class="timelIconBorder timelIcon-user">
                        <b-icon
                          pack="fas"
                          icon="trophy"
                          class="timelIcon"
                        > </b-icon>
                      </div>
                    </div>
                    <div class="tile timeLine"></div>
                  </div>
                  <div class="tile is-vertical timelNotesPos">
                    <div class="tile timelMessage">
                      Fin de la semaine d'onboarding !
                    </div>
                    <br />
                  </div>
                </div>
                <!-- INSCRIPTION -->
                <div class="tile is-parent feed">
                  <div class="tile is-2 timelDate">
                    {{ userDetail.createdAt | moment }}
                  </div>
                  <div
                    class="tile is-2 is-vertical"
                    style="display: flex !important;justify-content: center !important;"
                  >
                    <div
                      class="tile"
                      style="max-height: 30px !important;display: flex !important;justify-content: center !important;"
                    >
                      <div class="timelIconBorder timelIcon-user">
                        <b-icon
                          pack="fas"
                          icon="trophy"
                          class="timelIcon"
                        > </b-icon>
                      </div>
                    </div>
                    <div class="tile"></div>
                  </div>
                  <div class="tile is-vertical timelNotesPos">
                    <div class="tile timelMessage">Inscription réussie !</div>
                    <br />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </b-tab-item>
      <b-tab-item
        label="Gestion"
        v-if="userDetail"
      >
        <UserClients :charlieUser="userDetail"></UserClients>
      </b-tab-item>

    </b-tabs>

  </div>
</template>

<script>
import moment from 'moment';
import UserClients from './userClients.vue';

/**
 * @name user-details
 * @desc Component for displaying user details
 */

export default {
  name: 'user-details',
  components: {
    UserClients
  },
  props: {
    /**
     * User details object
     * @type {Object}
     */
    userDetail: {
      type: Object
    },
    /**
     * Array of missions
     * @type {Array}
     */
    missions: {
      type: Array
    }
  },
  data() {
    return {
      /**
       * @desc Array to store logs
       * @type {Array}
       */
      logs: [],
      /**
       * @desc Current log details
       * @type {Object}
       */
      currentLog: {
        operator: '',
        type: '',
        content: '',
        title: '',
        charlieUserId: ''
      },
      /**
       * @desc Array to store clients
       * @type {Array}
       */
      clients: [],
      /**
       * @desc Flag for sticky headers
       * @type {boolean}
       */
      stickyHeaders: true,
      /**
       * @desc Date of last contact
       * @type {Date}
       */
      lastContact: null
    };
  },
  watch: {
    userDetail: {
      immediate: true,
      deep: true,
      /**
       * @name handleUserDetailChange
       * @desc Handles changes in user details
       * @param {Object} newValue - New user detail value
       * @param {Object} oldValue - Old user detail value
       */
      handler(newValue, oldValue) {
        this.$store.dispatch('getAdminUserLogs', newValue?.id).then((res) => {
          this.logs = res.data;
          if (this.logs && this.logs.length > 0) {
            let filteredLogs = this.logs.filter(
              (log) =>
                log.type === 'FOLLOWUP' ||
                log.type === 'SUPPORT' ||
                log.type === 'PHONE_SUPPORT' ||
                log.type === 'MAIL_SUPPORT' ||
                log.type === 'RAPPEL' ||
                log.type === 'TEXT_SUPPORT'
            );
            if (filteredLogs.length > 0) {
              this.lastContact = filteredLogs[0].createdAt;
            } else {
              this.lastContact = this.userDetail.createdAt;
            }
          } else {
            this.lastContact = this.userDetail.createdAt;
          }
        });
      }
    }
  },
  filters: {
    /**
     * @name moment
     * @desc Filter to format date using moment.js
     * @param {Date} date - Date to format
     * @returns {String} - Formatted date string
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('DD/MM/YYYY');
    }
  },
  methods: {
    /**
     * Calculate the number of days between two dates
     * @param {Date} date1 - The first date
     * @returns {string} - The number of days between the dates in French
     */
    daysBetween(date1) {
      const momentDate1 = moment(date1);
      const now = moment();
      const days = now.diff(momentDate1, 'days');
      if (momentDate1.isSame(moment(), 'day')) {
        return "aujourd'hui";
      } else if (days === 1 || days === 0) {
        return 'hier';
      } else if (days < 7) {
        return days + ' jours';
      } else if (days < 30) {
        const weeks = Math.floor(days / 7);
        return weeks + (weeks > 1 ? ' semaines' : ' semaine');
      } else {
        const months = now.diff(momentDate1, 'months', true);
        return (
          Math.floor(months) + (Math.floor(months) > 1 ? ' mois' : ' mois')
        );
      }
    },

    /**
     * Compute the status information for an interview
     * @param {Object} interview - The interview object
     * @returns {Array} - An array containing the computed status information
     */
    computeStatusInfos(interview) {
      if (!interview) return [];
      let interviewStatus = new Object({
        color: '#828282 !important',
        date: this.daysBetween(
          interview.lastUserActionAt || interview.createdAt
        )
      });
      let lastLog = interview.ownerLogs[0];

      if (interview.lastNotifStatus === 'noAgree') {
        interviewStatus = {
          ...interviewStatus,
          icon: 'xmark',
          text: 'refuse'
        };
      } else if (
        interview.status == 3 &&
        interview.unlocked === false &&
        interview.loading == false
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'warning',
          text: 'received',
          color: '#f1054d !important'
        };
      } else if (
        interview.status == 3 &&
        interview.loading === false &&
        interview.unlocked === true
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'circle-play',
          text: 'seeTestimony',
          color: '#7957d5 !important'
        };
      } else if (
        (!interview.hasOwnerLogs &&
          !lastLog &&
          (interview.status === 1 || interview.status === 2)) ||
        (lastLog?.createdAt < interview.lastUserActionAt &&
          (interview.status === 1 || interview.status === 2))
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'arrow-pointer',
          text: 'clicked',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      } else if (
        lastLog &&
        lastLog.createdAt >
          (interview.lastUserActionAt || interview.createdAt) &&
        lastLog.type === 'RESENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'resent',
          date: this.daysBetween(lastLog.createdAt)
        };
      } else if (
        (!interview.hasOwnerLogs &&
          ((interview.status === 0 &&
            interview.interviewTemplate.status !== 0 &&
            interview.sentByCharlie) ||
            (interview.status === 0 &&
              interview.interviewTemplate.status == 1 &&
              !interview.sentByCharlie))) ||
        lastLog?.type === 'SENT'
      ) {
        interviewStatus = {
          ...interviewStatus,
          icon: 'hourglass',
          text: 'sent',
          date: this.daysBetween(
            lastLog
              ? lastLog.createdAt
              : interview.lastUserActionAt || interview.createdAt
          )
        };
      }
      return [interviewStatus];
    },

    /**
     * Save the user details by dispatching an update action
     */
    saveUserDetail() {
      delete this.userDetail.playlists;
      this.$store.dispatch('updateUserAdmin', this.userDetail);
    },

    /**
     * Calculate the total number of invitations with interviews
     * @returns {number} - The total number of invitations with interviews
     */
    sumInvitations() {
      return this.missions.filter((mission) => mission.interviews.length > 0)
        .length;
    },

    /**
     * Calculate the total number of unlocked testimonials
     * @returns {number} - The total number of unlocked testimonials
     */
    unlockedTestimonials() {
      let sum = 0;
      this.missions.forEach((mission) => {
        sum += mission.interviews.filter(
          (interview) => interview.unlocked
        ).length;
      });
      return sum;
    },

    /**
     * Calculate the total number of locked testimonials
     * @returns {number} - The total number of locked testimonials
     */
    lockedTestimonials() {
      let sum = 0;
      this.missions.forEach((mission) => {
        sum += mission.interviews.filter(
          (interview) => interview.status == 3
        ).length;
      });
      return sum;
    },

    /**
     * Compute color based on a given value
     * @param {number} value - The value to determine the color for
     * @returns {string} - The color code based on the value
     */
    computeColorFromValue(value) {
      if (value == 0) {
        return '#ffe9e9';
      } else if (value < 30) {
        return '#ffcbc4';
      } else if (value < 50) {
        return '#f7db5e';
      } else {
        return '#9ffcb0';
      }
    },

    /**
     * Handle sorting based on field and order
     * @param {string} field - The field to sort by
     * @param {string} order - The order of sorting
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.refreshList();
    },

    /**
     * Calculate the success rate of interviews
     * @returns {number} - The success rate percentage
     */
    scoring() {
      let sum = 0;
      let successSum = 0;
      this.missions.forEach((mission) => {
        sum += mission.interviews?.length || 0;
        successSum += mission.interviews.filter(
          (interview) => interview.status == 3
        ).length;
      });
      return sum > 0 ? Math.round((successSum / sum) * 100) : 0;
    },

    /**
     * Create a log entry
     */
    createLog() {
      let log = new Object();
      log = {
        operator: this.$store.state.user.surname,
        type: this.currentLog.type,
        content: this.currentLog.content,
        charlieUserId: this.userDetail.id
      };
      this.currentLog = {
        operator: '',
        type: '',
        content: '',
        title: '',
        charlieUserId: ''
      };
      this.$store.dispatch('createAdminUserLog', log).then((res) => {
        this.$store
          .dispatch('getAdminUserLogs', this.userDetail.id)
          .then((res) => {
            this.logs = res.data;
          });
      });
    },

    /**
     * Get the title based on the log type
     * @param {object} log - The log object
     * @returns {string} - The title corresponding to the log type
     */
    titleFromtype(log) {
      if (log.title) {
        return log.title;
      }
      switch (log.type) {
        case 'FOLLOWUP':
          return 'Suivi Mensuel';
        case 'SUPPORT':
          return 'Support Technique';
        case 'PHONE_SUPPORT':
          return 'Échange téléphonique';
        case 'MAIL_SUPPORT':
          return 'Échange par mail';
        case 'TEXT_SUPPORT':
          return 'Échange par SMS';
        case 'RAPPEL':
          return 'Rappel programmé';
        case 'OTHER':
          return 'Autre';
        default:
          return log.type;
      }
    },

    /**
     * Get the icon based on the type
     * @param {string} type - The type of the log
     * @returns {string} - The icon corresponding to the log type
     */
    iconFromType(type) {
      switch (type) {
        case 'FOLLOWUP':
          return 'calendar-check';
        case 'SUPPORT':
          return 'wrench';
        case 'PHONE_SUPPORT':
          return 'phone';
        case 'MAIL_SUPPORT':
          return 'envelope';
        case 'TEXT_SUPPORT':
          return 'message';
        case 'RAPPEL':
          return 'bell';
        case 'SUCCESS':
          return 'trophy';
        case 'NEW_TESTIMONIAL':
          return 'circle-play';
        case 'NEW_UNLOCK':
          return 'unlock';
        case 'NEW_INTERVIEW':
          return 'trophy';
        case 'NEW_PURCHASE':
          return 'money-bill';
        case 'OTHER':
          return 'circle-info';
        default:
          return 'circle-info';
      }
    },
    /**
     * Determines the class based on the log type for styling icons.
     * @param {string} type - The type of the log
     * @returns {string} - The class name for styling the icon
     */
    classFromType(type) {
      switch (type) {
        case 'FOLLOWUP':
          return 'timelIconBorder timelIcon-cs';
        case 'SUPPORT':
          return 'timelIconBorder timelIcon-support';
        case 'PHONE_SUPPORT':
          return 'timelIconBorder timelIcon-cs';
        case 'MAIL_SUPPORT':
          return 'timelIconBorder timelIcon-cs';
        case 'TEXT_SUPPORT':
          return 'timelIconBorder timelIcon-cs';
        case 'RAPPEL':
          return 'timelIconBorder timelIcon-cs';
        case 'SUCCESS':
          return 'timelIconBorder timelIcon-user';
        case 'NEW_TESTIMONIAL':
          return 'timelIconBorder timelIcon-user';
        case 'NEW_UNLOCK':
          return 'timelIconBorder timelIcon-user';
        case 'NEW_INTERVIEW':
          return 'timelIconBorder timelIcon-user';
        case 'NEW_PURCHASE':
          return 'timelIconBorder timelIcon-user';
        case 'OTHER':
          return 'timelIconBorder timelIcon-cs';
        default:
          return 'timelIconBorder timelIcon-user';
      }
    }
  }
};
</script>

<style src="../index.css"></style>

<style scoped>
p {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}
</style>
