<template>
  <section>
    <div>
      <b-field>
        <b-upload
          v-model="tempFile"
          native
          :accept="allowedFilesExt"
          drag-drop
          style="width: 100% !important; height: 45px;"
        >
          <div> <img
              src="../../public/img/importVideo2.png"
              style="max-width: 100px!important; height: 30px;"
            />
          </div>
          <div>
            <p style="font-size:14px">{{ $t('importVideo') }}</p>
          </div>
        </b-upload>
      </b-field>
      <div
        class="tags"
        v-if="tempFile"
      >
        <span class="tag is-primary">
          {{ tempFile.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile()"
          ></button>
        </span>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="loading"
    >
    </b-loading>

  </section>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

/**
 * @name uploadermission
 * @desc Component for handling file uploads and validations
 */
export default {
  name: 'uploadermission',
  filters: {
    /**
     * @name truncate
     * @desc Truncates text based on length and adds a suffix if necessary
     */
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  data: () => {
    return {
      /**
       * @type {string}
       */
      imgBG: '',
      /**
       * @type {null}
       */
      file: null,
      /**
       * @type {null}
       */
      tempFile: null,
      /**
       * @type {boolean}
       */
      loading: false,
      /**
       * @type {string}
       */
      allowedFilesExt: '.mp4,.mov,.avi,.MP4,.MOV,.AVI',
      /**
       * @type {number}
       */
      allowedFilesNb: 1
    };
  },

  watch: {
    tempFile: {
      handler() {
        if (this.tempFile) {
          this.checkFileExt();
          this.checkDuration();
        }
      },
      deep: true
    }
  },

  methods: {
    /**
     * @name deleteDropFile
     * @desc Deletes the current file being uploaded
     */
    deleteDropFile() {
      this.tempFile = null;
      this.file = null;
    },
    /**
     * @name checkFileExt
     * @desc Checks if the file extension is allowed
     */
    checkFileExt() {
      if (
        !this.allowedFilesExt
          .split(',')
          .includes('.' + this.tempFile.name.split('.').pop())
      ) {
        this.tempFile = null;
        this.$buefy.toast.open({
          message: this.$t('errorFormat'),
          type: 'is-danger',
          position: 'is-top'
        });
      }
    },
    /**
     * @name checkDuration
     * @desc Checks the duration of a video file
     */
    checkDuration() {
      console.log('Checkduration');
      this.loading = true;
      if (this.tempFile.type.startsWith('video')) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var videoElement = document.createElement('video');
          videoElement.preload = 'metadata';
          videoElement.type = 'video/mp4';
          videoElement.addEventListener('loadedmetadata', () => {
            console.log(videoElement.duration);
            if (videoElement.duration > 90) {
              this.tempFile = null;
              this.loading = false;
              this.$buefy.toast.open({
                message: this.$t('errorDuration'),
                type: 'is-danger',
                position: 'is-top'
              });
            } else {
              this.file = this.tempFile;
              this.loading = false;
              this.emitData();
            }
          });
          videoElement.addEventListener('error', () => {
            const readChunk = (chunkSize, offset) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                  if (event.target.error) {
                    reject(event.target.error);
                  }
                  resolve(new Uint8Array(event.target.result));
                };
                reader.readAsArrayBuffer(
                  this.tempFile.slice(offset, offset + chunkSize)
                );
              });
            const getSize = () => this.tempFile.size;
            try {
              window.MediaInfo.mediaInfoFactory(
                { format: 'JSON' },
                (mediainfo) => {
                  mediainfo.analyzeData(getSize, readChunk).then((result) => {
                    let data = JSON.parse(result);
                    let duration = data.media.track.find(
                      (t) => t['@type'] == 'General'
                    ).Duration;
                    console.log('duration : ' + duration);
                    if (duration > 90) {
                      this.tempFile = null;
                      this.loading = false;
                      this.$buefy.toast.open({
                        message: this.$t('errorDuration'),
                        type: 'is-danger',
                        position: 'is-top'
                      });
                    } else {
                      this.loading = false;
                      this.file = this.tempFile;
                      this.emitData();
                    }
                  });
                },
                (error) => {
                  console.error(error);
                  this.tempFile = null;
                  this.loading = false;
                  this.$buefy.toast.open({
                    message: this.$t('errorAnalysis'),
                    type: 'is-danger',
                    position: 'is-top'
                  });
                }
              );
            } catch (e) {
              this.tempFile = null;
              this.loading = false;
              console.error(e);
              this.$buefy.toast.open({
                message: this.$t('errorAnalysis'),
                type: 'is-danger',
                position: 'is-top'
              });
            }
          });
          videoElement.src = e.target.result;
        };
        reader.readAsDataURL(this.tempFile);
      } else {
        this.loading = false;
        this.$buefy.toast.open({
          message: this.$t('errorFormat'),
          type: 'is-danger',
          position: 'is-top'
        });
      }
    },
    /**
     * @name dataURLtoBlob
     * @desc Converts data URL to Blob
     */
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    /**
     * @name emitData
     * @desc Emits uploaded media data
     */
    emitData() {
      let filename = uuidv4();

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        let blob = this.dataURLtoBlob(event.target.result);
        let datas = {
          filename: filename,
          blob: blob
        };
        this.$emit('uploadedMedia', datas);
      });
      reader.readAsDataURL(this.file);
    }
  },
  beforeDestroy() {
    clearInterval(this.timerUpdater);
  }
};
</script>

<style src="./index.css"></style>