
<template>
  <section>

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div
          class="tile is-child has-text-centered"
          :class="isClient ? 'is-2' : 'is-5'"
        >
        </div>
        <div class="tile is-child is-2 has-text-centered">
          <h3>{{ totalUsersCount }}</h3>
          <p>people</p>
        </div>
        <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{ totalInvoicesCount }}</h3>
          <p>ventes</p>
        </div>
        <!-- <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{amountPerUserMean}} €</h3>
          <p>panier client</p>
        </div> -->
        <div
          v-if="isClient"
          class="tile is-child is-2 has-text-centered"
        >
          <h3>{{ invoicesPerUserMean }}</h3>
          <p>achat par clients</p>
        </div>
        <div
          class="tile is-child has-text-centered"
          :class="isClient ? 'is-2' : 'is-5'"
        >
        </div>
      </div>

    </div>

    <hr />
    <div
      class="tile is-ancestor"
      style="width: 1400px; margin:auto"
    >
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>source</p>
        <CustomChart
          v-if=userSource
          :chartData=userSource
        ></CustomChart>

      </div>
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>taille d'entreprise</p>
        <CustomChart
          v-if=businessSize
          :chartData=businessSize
        ></CustomChart>

      </div>

      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>cible</p>
        <CustomChart
          v-if=businessTarget
          :chartData=businessTarget
        ></CustomChart>

      </div>
      <div class="tile is-parent is-3 is-vertical has-text-centered">
        <p>position</p>
        <CustomChart
          v-if=positionInCompany
          :chartData=positionInCompany
        ></CustomChart>

      </div>
    </div>
    <hr />

    <div
      class="tile is-ancestor"
      style="width: 1600px; margin:auto"
    >

      <div class="tile is-parent ">

        <div
          class="tile is-vertical is-5"
          style="padding-right: 20px;height: 100%"
        >
          <p>secteurs d'activités</p>
          <Sector
            :chartData=businessSector
            style="height:90%"
          ></Sector>
        </div>
        <div
          class="tile is-vertical"
          v-if="businessRegions && businessRegions.length > 0"
        >
          <p>Carte</p>
          <ClientsMap
            :regions="businessRegions"
            style="width:800px"
          ></ClientsMap>
        </div>
      </div>

    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="loading"
    ></b-loading>
  </section>
</template>

<script>
import moment from 'moment';
import Sector from './chart/sector.vue';
import CustomChart from './chart/customChart.vue';
import ClientsMap from './chart/clientsMap.vue';

/**
 * @name allOrders
 * @desc Component for displaying all client data
 */

export default {
  name: 'allOrders',
  components: { Sector, CustomChart, ClientsMap },
  props: {
    /**
     * Flag indicating if the user is a client
     */
    isClient: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    /**
     * Filter to format dates using moment.js
     * @param {Date} date - The date to format
     * @returns {String} - Formatted date
     */
    moment: function (date) {
      moment.locale('fr');
      return moment(date).format('LLL');
    }
  },
  data: () => {
    return {
      /**
       * @desc Flag indicating if data is loading
       * @type {boolean}
       */
      loading: false,
      /**
       * @desc Size of the business
       * @type {null}
       */
      businessSize: null,
      /**
       * @desc Target of the business
       * @type {null}
       */
      businessTarget: null,
      /**
       * @desc Position in the company
       * @type {null}
       */
      positionInCompany: null,
      /**
       * @desc Total count of invoices
       * @type {null}
       */
      totalInvoicesCount: null,
      /**
       * @desc Mean of invoices per user
       * @type {null}
       */
      invoicesPerUserMean: null,
      /**
       * @desc Mean amount per user
       * @type {null}
       */
      amountPerUserMean: null,
      /**
       * @desc Total count of users
       * @type {null}
       */
      totalUsersCount: null,
      /**
       * @desc Business sector data
       * @type {null}
       */
      businessSector: null,
      /**
       * @desc Regions of the business
       * @type {null}
       */
      businessRegions: null,
      /**
       * @desc Source of the user
       * @type {null}
       */
      userSource: null
    };
  },
  beforeMount() {
    this.refreshData();
  },
  methods: {
    /**
     * @name refreshData
     * @desc Refreshes the data by fetching admin business statistics
     */
    refreshData() {
      this.loading = true;
      this.$store
        .dispatch('getAdminBusinessStats', { isClient: this.isClient })
        .then((res) => {
          this.loading = false;
          let stats = res.data;
          this.businessSize = this.computeBusinessSizeChart(stats.businessSize);
          this.businessTarget = this.computeBusinessTargetChart(
            stats.businessTarget
          );
          this.positionInCompany = this.computePositionInCompanyChart(
            stats.positionInCompany
          );
          this.businessSector = this.computebusinessSectorChart(
            stats.businessSector
          );
          this.totalInvoicesCount = stats.totalInvoicesCount;
          this.invoicesPerUserMean = stats.invoicesPerUserMean;
          this.amountPerUserMean = stats.amountPerUserMean;
          this.totalUsersCount = stats.totalUsersCount;
          this.businessRegions = stats.businessRegions;
          this.userSource = this.computeSourceChart(stats.userSource);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    /**
     * @name computebusinessSectorChart
     * @desc Computes data for business sector chart
     * @param {Array} businessSector - Array of business sector data
     * @returns {Object} - Formatted data for chart
     */
    computebusinessSectorChart(businessSector) {
      let data = businessSector.map((item) => {
        return {
          label: item.businessSector,
          value: item.businessSectorCount
        };
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: '#9A9AF4',
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    },
    computeSourceChart(userSource) {
      let data = userSource.map((item) => {
        switch (item.userSource) {
          case 'search':
            return {
              label: 'search',
              value: item.userSourceCount,
              color: '#32A350',
              order: 0
            };
          case 'youtube':
            return {
              label: 'youtube',
              value: item.userSourceCount,
              color: '#c4302b',
              order: 1
            };
          case 'linkedin':
            return {
              label: 'linkedin',
              value: item.userSourceCount,
              color: '#0e76a8',
              order: 2
            };
          case 'facebook':
            return {
              label: 'facebook',
              value: item.userSourceCount,
              color: '#3B5998',
              order: 3
            };
          case 'instagram':
            return {
              label: 'instagram',
              value: item.userSourceCount,
              color: '#C32AA3',
              order: 4
            };
          case 'tiktok':
            return {
              label: 'tiktok',
              value: item.userSourceCount,
              color: '#000000',
              order: 5
            };
          case 'webikeo':
            return {
              label: 'webikeo',
              value: item.userSourceCount,
              color: '#5934D9',
              order: 6
            };
          case 'gartner':
            return {
              label: 'gartner',
              value: item.userSourceCount,
              color: '#01143C',
              order: 7
            };
          case 'appsumo':
            return {
              label: 'appsumo',
              value: item.userSourceCount,
              color: '#FFEE4E',
              order: 8
            };
          case 'affiliation':
            return {
              label: 'affiliation',
              value: item.userSourceCount,
              color: '#55ABE3',
              order: 9
            };
          case 'referral':
            return {
              label: 'referral',
              value: item.userSourceCount,
              color: '#FBBC05',
              order: 10
            };
          case 'other':
            return {
              label: 'other',
              value: item.userSourceCount,
              color: '#828282',
              order: 11
            };
        }
      });
      data.sort((a, b) => {
        return a.order - b.order;
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: data.map((item) => {
              return item.color;
            }),
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    },
    computePositionInCompanyChart(positionInCompany) {
      let data = positionInCompany.map((item) => {
        switch (item.positionInCompany) {
          case 'Apprenti':
            return {
              label: 'Apprenti',
              value: item.positionInCompanyCount,
              color: '#F7BAFF',
              order: 0
            };
          case 'Direction':
            return {
              label: 'Direction',
              value: item.positionInCompanyCount,
              color: '#4A106D',
              order: 3
            };
          case "Chef d'équipe":
            return {
              label: "Chef d'équipe",
              value: item.positionInCompanyCount,
              color: '#840395',
              order: 2
            };
          case 'Opérateur':
            return {
              label: 'Opérateur',
              value: item.positionInCompanyCount,
              color: '#D85FE8',
              order: 1
            };
        }
      });
      data.sort((a, b) => {
        return a.order - b.order;
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: data.map((item) => {
              return item.color;
            }),
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    },
    computeBusinessTargetChart(businessTarget) {
      let data = businessTarget.map((item) => {
        switch (item.businessTarget) {
          case 'B2B':
            return {
              label: 'BtoB',
              value: item.businessTargetCount,
              color: '#0D8DE5'
            };
          case 'B2C':
            return {
              label: 'BtoC',
              value: item.businessTargetCount,
              color: '#7ED9F0'
            };
        }
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: data.map((item) => {
              return item.color;
            }),
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    },
    computeBusinessSizeChart(businessSize) {
      let data = businessSize.map((item) => {
        switch (item.businessSize) {
          case '1':
            return {
              label: '1',
              value: item.businessSizeCount,
              color: '#9A9AF4',
              order: 0
            };
          case '2-10':
            return {
              label: '2-10',
              value: item.businessSizeCount,
              color: '#5C5CE0',
              order: 1
            };
          case '11-50':
            return {
              label: '11-50',
              value: item.businessSizeCount,
              color: '#202097',
              order: 2
            };
          case '51-500':
            return {
              label: '51-500',
              value: item.businessSizeCount,
              color: '#121258',
              order: 3
            };
          case '500+':
            return {
              label: '500+',
              value: item.businessSizeCount,
              color: '#000009',
              order: 4
            };
        }
      });
      data.sort((a, b) => {
        return a.order - b.order;
      });
      return {
        labels: data.map((item) => {
          return item.label;
        }),
        datasets: [
          {
            backgroundColor: data.map((item) => {
              return item.color;
            }),
            data: data.map((item) => {
              return item.value;
            })
          }
        ]
      };
    }
  }
};
</script>
  
<style src="../index.css"></style>